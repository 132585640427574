<template>
  <CRow>
    <CCol lg="12">
      <CCard>
        <CCardHeader>
          <CRow>
            <CCol>
              <h3 class="card-title">
                <strong>{{ fullName }}</strong
                >{{ $t("profilePage.familyInformation") }}
              </h3>
            </CCol>
          </CRow>
        </CCardHeader>

        <CCardBody>
          <CRow>
            <CCol lg="6" class="mb-3">
              <CButton @click="onFamilyAddClick()" color="info"
                ><i class="icon cil-user-follow"></i>{{ $t("profilePage.familyRegister") }}</CButton
              >
            </CCol>
          </CRow>
          <CRow>
            <CCol
              lg="6"
              v-for="data in families"
              :key="data.id"
              class="float-left"
            >
              <CCard>
                <CCardHeader>
                  <CInput
                    :label="$t('profilePage.cardID')"
                    horizontal
                    plaintext
                    v-model="data.relative.cardId"
                  />
                </CCardHeader>
                <CCardBody>
                  <CForm class="member-info edit-form">
                    <CRow>
                      <CCol lg="12">
                        <CInput
                          :label="$t('profilePage.lastName')"
                          horizontal
                          plaintext
                          v-model="data.relative.familyName"
                        />
                      </CCol>
                      <CCol lg="12">
                        <CInput
                          :label="$t('profilePage.lastNamePronunciation')"
                          horizontal
                          plaintext
                          v-model="data.relative.firstName"
                        />
                      </CCol>
                      <CCol lg="12">
                        <CInput
                          :label="$t('profilePage.firstName')"
                          horizontal
                          plaintext
                          v-model="data.relative.givenName"
                        />
                      </CCol>

                      <CCol lg="12">
                        <CInput
                          :label="$t('profilePage.firstNamePronunciation')"
                          horizontal
                          plaintext
                          v-model="data.relative.lastName"
                        />
                      </CCol>
                      <CCol lg="12">
                        <CSelect
                          :label="$t('profilePage.gender')"
                          horizontal
                          disabled
                          :options="options.genderOptions"
                          v-model="data.relative.gender"
                        />
                      </CCol>
                      <CCol lg="12">
                        <!-- <CInput
                          label="生年月日"
                          horizontal
                          plaintext
                          v-model="data.relative.birth"
                        /> -->
                        <div class="form-group form-row">
                          <label class="col-form-label col-sm-3"
                            >{{$t("profilePage.birthDate")}}</label
                          >
                          <div class="col-sm-9">
                            <span>{{
                              data.relative.birth | formatDate
                            }}</span>
                          </div>
                        </div>
                      </CCol>
                      <CCol lg="12">
                        <CSelect
                          :label="$t('profilePage.relationship')"
                          horizontal
                          disabled
                          :options="options.relationOptions"
                          :value.sync="data.description"
                        />
                        <!-- v-model="data.name" /> -->
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
                <CCardFooter>
                  <CButton @click="onFamilyEditClick(data.id)" color="info"
                    ><i class="icon cil-pencil"></i> {{$t("common.edit")}}</CButton
                  >
                </CCardFooter>
              </CCard>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>

    <CModal
      color="dark"
      :closeOnBackdrop="false"
      :centered="true"
      :show.sync="addFamilyModal"
    >
      <CRow>
        <!-- <CCol lg="12">
          <CInput label="氏" horizontal v-model="familyAdd.givenName" />

        </CCol>
        <CCol lg="12">
          <CInput
            label="氏（カナ）"
            horizontal
            v-model="familyAdd.familyName"
          />
        </CCol> -->

        <CCol lg="12">
          <div
            class="text-danger input-info"
            v-if="
              $v.familyAdd.familyName.$dirty &&
              !$v.familyAdd.familyName.required
            "
          >{{$t("profilePage.lastNameRequired")}}
            
          </div>
          <div class="required-mark form-group form-row">
            <label class="col-form-label col-sm-3">{{ $t("profilePage.lastName") }}</label>
            <div class="col-sm-9">
              <input
                type="text"
                class="form-control"
                v-model="familyAdd.familyName"
                v-on:input="familyName_furigana"
              />
            </div>
          </div>
        </CCol>

        <CCol lg="12">
          <div
            class="text-danger input-info"
            v-if="
              $v.familyAdd.firstName.$dirty && !$v.familyAdd.firstName.required
            "
          >{{$t("profilePage.lastNameKanaRequired")}}
          </div>
          <CInput
            :label="$t('profilePage.lastNamePronunciation')"
            class="required-mark"
            horizontal
            v-model="familyAdd.firstName"
          />
        </CCol>

        <!-- <CCol lg="12">
          <CInput label="名" horizontal v-model="familyAdd.lastName" />
        </CCol> -->

        <!-- <CCol lg="12">
          <CInput label="名（カナ）" horizontal v-model="familyAdd.firstName" />
        </CCol> -->

        <CCol lg="12">
          <div
            class="text-danger input-info"
            v-if="
              $v.familyAdd.givenName.$dirty && !$v.familyAdd.givenName.required
            "
          >
          {{$t("profilePage.firstNameRequired")}}
          </div>
          <!-- <CInput
                    label="名"
                    class="required-mark"
                    horizontal
                    v-model="itemToEdit.givenName"
                  /> -->
          <div class="required-mark form-group form-row">
            <label class="col-form-label col-sm-3">{{ $t("profilePage.firstName") }}</label>
            <div class="col-sm-9">
              <input
                type="text"
                class="form-control"
                v-model="familyAdd.givenName"
                v-on:input="givenName_furigana"
              />
            </div>
          </div>
        </CCol>
        <CCol lg="12">
          <div
            class="text-danger input-info"
            v-if="
              $v.familyAdd.lastName.$dirty && !$v.familyAdd.lastName.required
            "
          >
            {{$t("profilePage.firstNameKanaRequired")}}
          </div>
          <CInput
            :label="$t('profilePage.firstNamePronunciation')"
            class="required-mark"
            horizontal
            v-model="familyAdd.lastName"
          />
        </CCol>

        <CCol lg="12">
          <CSelect
            :label="$t('profilePage.gender')"
            horizontal
            :options="options.genderOptions"
            :value.sync="familyAdd.gender"
          />
          <!-- <div
            class="text-danger input-info"
            v-if="!$v.familyAdd.gender.required"
          >
            性別の入力は必須です
          </div> -->
        </CCol>
        <CCol lg="12">
          <CInput
            :label="$t('profilePage.birthDate')"
            type="date"
            horizontal
            v-model="familyAdd.birth"
          />
          <!-- <div
            class="text-danger input-info"
            v-if="!$v.familyAdd.birth.required"
          >
            生年月日の入力は必須です
          </div> -->
        </CCol>
        <CCol lg="12">
          <CSelect
            :label="$t('profilePage.relationship')"
            horizontal
            :options="options.relationOptions"
            :value.sync="familyAdd.relation"
          />
          <!-- <div
            class="text-danger input-info"
            v-if="!$v.familyAdd.relation.required"
          >
            関係の入力は必須です
          </div> -->
        </CCol>
      </CRow>
      <template #header>{{ $t("profilePage.familyRegister") }}</template>
      <template #footer>
        <CButton
          @click="addFamilyModal = false"
          color="secondary"
          class="float-left"
        >
          <i class="icon cil-x"></i> {{ $t("common.cancel") }}
        </CButton>
        <CButton @click="onFamilyAddRunClick()" color="success">
          <i class="icon cil-save"></i> {{ $t("common.register") }}
        </CButton>
        <!-- <CButton
          @click="onFamilyAddRunClick()"
          color="success"
          :disabled="$v.familyAdd.$invalid"
        >
          <i class="icon cil-save"></i> 登録
        </CButton> -->
      </template>
    </CModal>

    <CModal
      color="dark"
      :closeOnBackdrop="false"
      :centered="true"
      :show.sync="editFamilyModal"
    >
      <CRow>
        <CCol lg="12">
          <div class="required-mark form-group form-row">
            <label class="col-form-label col-sm-3">{{$t("profilePage.lastName")}}</label>
            <div class="col-sm-9">
              <input
                type="text"
                class="form-control"
                v-model="familyEdit.familyName"
                v-on:input="familyName_furigana"
              />
            </div>
          </div>

          <!-- <CInput label="氏" horizontal v-model="familyEdit.familyName" /> -->
        </CCol>

        <CCol lg="12">
          <CInput
            :label="$t('profilePage.lastNamePronunciation')"
            horizontal
            v-model="familyEdit.firstName"
          />
        </CCol>

        <CCol lg="12">
          <div class="required-mark form-group form-row">
            <label class="col-form-label col-sm-3">{{$t("profilePage.firstName")}}</label>
            <div class="col-sm-9">
              <input
                type="text"
                class="form-control"
                v-model="familyEdit.givenName"
                v-on:input="givenName_furigana"
              />
            </div>
          </div>
        </CCol>
        <CCol lg="12">
          <CInput :label="$t('profilePage.firstNamePronunciation')" horizontal v-model="familyEdit.lastName" />
        </CCol>
        <!-- <CCol lg="12">
          <CInput label="名" horizontal v-model="familyEdit.givenName" />
        </CCol>
        <CCol lg="12">
          <CInput label="氏（カナ）" horizontal v-model="familyEdit.lastName" />
        </CCol> -->
        <!-- <CCol lg="12">
          <CInput
            label="名（カナ）"
            horizontal
            v-model="familyEdit.firstName"
          />
        </CCol> -->
        <CCol lg="12">
          <CSelect
            :label="$t('profilePage.gender')"
            horizontal
            required
            :options="options.genderOptions"
            :value.sync="familyEdit.gender"
          />
        </CCol>
        <CCol lg="12">
          <CInput
            :label="$t('profilePage.birthDate')"
            type="date"
            horizontal
            required
            v-model="familyEdit.birth"
          />
        </CCol>
        <CCol lg="12">
          <CSelect
            :label="$t('profilePage.relationship')"
            horizontal
            required
            :options="options.relationOptions"
            :value.sync="familyEdit.relation"
          />
        </CCol>
      </CRow>
      <template #header> {{$t("profilePage.familyEdit")}}</template>
      <template #footer>
        <CButton
          @click="editFamilyModal = false"
          color="secondary"
          class="float-left"
        >
          <i class="icon cil-x"></i> {{$t("common.cancel")}}
        </CButton>
        <CButton @click="onFamilySaveRunClick()" color="success">
          <i class="icon cil-save"></i>{{$t("common.save")}}
        </CButton>
        <CButton @click="onFamilyDeleteClick()" color="danger">
          <i class="icon cil-trash"></i> {{$t("common.delete")}}
        </CButton>
      </template>
    </CModal>

    <CModal
      color="success"
      closeOnBackdrop
      :centered="true"
      :show.sync="completedModal"
    >
      {{$t("commonMessage.updateSuccessText")}}
      <template #header> {{$t("commonMessage.updateSuccessHeader")}} </template>
      <template #footer>
        <CButton @click="completedModal = false" color="success"
          ><i class="icon cil-x"></i>{{$t("common.close")}}</CButton
        >
      </template>
    </CModal>

    <CModal
      color="warning"
      :closeOnBackdrop="false"
      :centered="true"
      :show.sync="deleteConfirmModal"
    >
      {{$t("commonMessage.deleteText")}}
      <template #header> {{$t("profilePage.deleteConfirm")}} </template>
      <template #footer>
        <CButton
          @click="deleteConfirmModal = false"
          color="secondary"
          class="float-left"
        >
          <i class="icon cil-x"></i>{{$t("common.cancel")}}
        </CButton>
        <CButton @click="onFamilyDeleteRunClick()" color="danger">
          <i class="icon cil-trash"></i> {{$t("profilePage.run")}}
        </CButton>
      </template>
    </CModal>

    <CModal
      color="success"
      closeOnBackdrop
      :centered="true"
      :show.sync="deleteCompleteModal"
    >
      {{$t("commonMessage.deleteSuccessText")}}
      <template #header> {{$t("commonMessage.deleteSuccessHeader")}} </template>
      <template #footer>
        <CButton @click="deleteCompleteModal = false" color="success"
          ><i class="icon cil-x"></i> {{$t("common.close")}}</CButton
        >
      </template>
    </CModal>
  </CRow>
</template>

<script>
import axios from "axios";
import Options from "./Options";
import config from "@/config"
import { required } from "vuelidate/lib/validators";
import historykana from "historykana";
import * as wanakana from "wanakana";

//会員システムのurl
const LIBERTY_MEMBER_SERVER_URL = config.LIBERTY_MEMBER_SERVER_URL;
// const LIBERTY_MEMBER_SERVER_URL = "https://points.liberty-resort.net/";

export default {
  name: "FamilyMember",
  components: {},
  data() {
    return {
      completedModal: false,
      addFamilyModal: false,
      editFamilyModal: false,
      deleteConfirmModal: false,
      deleteCompleteModal: false,

      tableItems: [],
      copyItems: [],
      families: [],
      familyAdd: {
        familyName: "",
        givenName: "",
        firstName: "",
        lastName: "",
      },
      familyEdit: {
        familyName: "",
        givenName: "",
        firstName: "",
        lastName: "",
      },

      relationId: "",
      fullName: "",

      options: Options.data(),
      history: [],
    };
  },

  validations: {
    familyAdd: {
      givenName: {
        required,
      },
      familyName: {
        required,
      },
      lastName: {},
      firstName: {},
      gender: {
        required,
      },
      birth: {
        required,
      },
      relation: {
        required,
      },
    },
  },

  methods: {
    /**
     * 家族会員登録の実装
     */
    addFamily() {
      // var urlRequest = "/rest/users/" + this.gUserName + "/relative";
      var urlRequest = `${LIBERTY_MEMBER_SERVER_URL}/rest/currentuser/relative`;
      
      console.log(this.familyAdd);
      axios
        .post(urlRequest, this.familyAdd, {
          headers: { Authorization: `Bearer ${this.$keycloak.token}` },
        })
        .then((resp) => {
          console.log("Family added:" + JSON.stringify(resp.data));
          this.getFamilies();
        })
        .catch((err) => {
          console.log("Family added error: " + err);
          this.errors = err;
        });
    },

    /**
     * 全家族会員の取得
     */
    getFamilies() {
      var urlRequest = `${LIBERTY_MEMBER_SERVER_URL}/rest/currentuser/relative`;

      axios
        .get(urlRequest, {
          headers: { Authorization: `Bearer ${this.$keycloak.token}` },
        })
        .then((resp) => {
          console.log("Families get: ");
          console.log(resp.data);
          this.families = [];
          this.families = resp.data;
        })
        .catch((err) => {
          console.log("Families get error: " + err);
          this.errors = err;
        });
    },

    /**
     * 家族会員1人分の取得（未使用）
     */
    getFamily() {
      var urlRequest = `${LIBERTY_MEMBER_SERVER_URL}/rest/currentuser/relative/` + this.relationId;

      axios
        .get(urlRequest, {
          headers: { Authorization: `Bearer ${this.$keycloak.token}` },
        })
        .then((resp) => {
          console.log("Family get:" + JSON.stringify(resp.data));
        })
        .catch((err) => {
          console.log("Families get error: " + err);
          this.errors = err;
        });
    },

    /**
     * 家族1人更新リクエスト
     */
    putFamily() {
      // NOTE: 会員更新同様の処理を行う
      var urlRequest = `${LIBERTY_MEMBER_SERVER_URL}/rest/users/` + this.familyEdit.name;

      console.log(this.familyEdit);
      axios
        .put(urlRequest, this.familyEdit, {
          headers: { Authorization: `Bearer ${this.$keycloak.token}` },
        })
        .then((resp) => {
          console.log("Family put:" + JSON.stringify(resp.data));
          // NOTE: 会員更新は関係がないため、別途関係更新リクエストが必要
          this.putRelation();
          this.getFamilies();
        })
        .catch((err) => {
          console.log("Family put error: " + err);
          this.errors = err;
        });
    },

    /**
     * 関係更新リクエスト
     */
    putRelation() {
      // NOTE: 会員と家族の関係更新のみの処理を行う
      // var urlRequest =
      //   "/rest/users/" + this.gUserName + "/relative/" + this.relationId;
            var urlRequest = `${LIBERTY_MEMBER_SERVER_URL}/rest/currentuser/relative/` + this.relationId;

      const body = {
        name: this.familyEdit.relation,
        description: this.familyEdit.relation,
      };

      console.log(body);
      axios
        .put(urlRequest, body, {
          // .put(urlRequest, this.familyEdit, {
          headers: { Authorization: `Bearer ${this.$keycloak.token}` },
        })
        .then((resp) => {
          console.log("Relation put:" + JSON.stringify(resp.data));
          this.getFamilies();
        })
        .catch((err) => {
          console.log("Family put error: " + err);
          this.errors = err;
        });
    },

    /**
     * 家族1人削除リクエスト
     */
    deleteFamily() {
      // var urlRequest =
      //   "/rest/users/" + this.gUserName + "/relative/" + this.relationId;
                  var urlRequest = `${LIBERTY_MEMBER_SERVER_URL}/rest/currentuser/relative/` + this.relationId;
      axios
        .delete(urlRequest, {
          headers: { Authorization: `Bearer ${this.$keycloak.token}` },
        })
        .then((resp) => {
          console.log("Family deleted:" + JSON.stringify(resp.data));
          this.getFamilies();
        })
        .catch((err) => {
          console.log("Family delete error: " + err);
          this.errors = err;
        });
    },

    /**
     * 家族登録ボタンクリック
     */
    onFamilyAddClick() {
      // 家族登録ダイアログのデータを初期化
      {
        this.familyAdd.givenName = "";
        this.familyAdd.familyName = "";
        this.familyAdd.lastName = "";
        this.familyAdd.firstName = "";
        this.familyAdd.gender = "UNKNOWN";
        this.familyAdd.birth = "";
        // note: passwordはパラメータ必須。値は空でよい。
        this.familyAdd.password = "";
        this.familyAdd.relation = "OTHER";
      }

      this.addFamilyModal = true;
    },

    /**
     * 家族登録ボタンクリック
     */
    onFamilyAddRunClick() {
      this.addFamily();
      this.addFamilyModal = false;
      this.completedModal = true;
    },

    /**
     * 家族編集ボタンクリック
     */
    onFamilyEditClick(id) {
      this.relationId = id;
      const editData = this.families.filter((a) => a.id == id)[0];

      // 家族編集ダイアログのデータを初期化
      {
        this.familyEdit.givenName = editData.relative.givenName || "";
        this.familyEdit.familyName = editData.relative.familyName || "";
        this.familyEdit.lastName = editData.relative.lastName || "";
        this.familyEdit.firstName = editData.relative.firstName || "";
        this.familyEdit.gender = editData.relative.gender || "UNKNOWN";
        this.familyEdit.birth = editData.relative.birth || "";
        // note: passwordはパラメータ必須。値は空でよい。
        this.familyEdit.password = "";
        this.familyEdit.relation = editData.description || "OTHER";
        // this.familyEdit.relationName = editData.name || "OTHER";
        this.familyEdit.userName = editData.relative.name || "";
        this.familyEdit.cardId = editData.relative.cardId || "";
        this.familyEdit.id = editData.relative.id || "";
        this.familyEdit.name = editData.relative.name || "";
        this.familyEdit.status = editData.relative.status || "";
      }

      this.editFamilyModal = true;
    },

    /**
     * 家族保存ボタンクリック
     */
    onFamilySaveRunClick() {
      this.putFamily();
      this.editFamilyModal = false;
      this.completedModal = true;
    },

    /**
     * 家族削除ボタンクリック
     */
    onFamilyDeleteClick() {
      this.deleteConfirmModal = true;
    },

    /**
     * 家族削除実行ボタンクリック
     */
    onFamilyDeleteRunClick() {
      this.deleteFamily();
      this.editFamilyModal = false;
      this.deleteConfirmModal = false;
      this.deleteCompleteModal = true;
    },

    /**
     * 家族カードID編集ボタンクリック
     */
    onFamilyCardIdEditClick(id) {
      this.relationId = id;
      const editData = this.families.filter((a) => a.id == id)[0];

      // 家族カードID編集ダイアログのデータを初期化
      {
        this.familyEdit.givenName = editData.relative.givenName || "";
        this.familyEdit.familyName = editData.relative.familyName || "";
        // this.familyEdit.lastName = editData.relative.lastName || "";
        // this.familyEdit.firstName = editData.relative.firstName || "";
        // this.familyEdit.gender = editData.relative.gender || "UNKNOWN";
        // this.familyEdit.birth = editData.relative.birth || "";
        // this.familyEdit.password = "";
        // this.familyEdit.relation = editData.description || "OTHER";
        // this.familyEdit.userName = editData.relative.name || "";
        this.familyEdit.cardId = editData.relative.cardId || "";
        // this.familyEdit.id = editData.relative.id || "";
        // this.familyEdit.name = editData.relative.name || "";
        // this.familyEdit.status = editData.relative.status || "";
      }

      this.editCardIdModal = true;
    },

    // TODO: 仕様確認
    /**
     * 家族カードID保存ボタンクリック
     */
    onFamilyCardIdSaveRunClick() {
      this.putFamilyCardId();
      this.editCardIdModal = false;
      this.completedModal = true;
    },

    // TODO: 仕様確認
    /**
     * 家族カードID更新リクエスト
     */
    putFamilyCardId() {},

    setFullName(value) {
      this.fullName = value;
    },
    familyName_furigana(input) {
      if (wanakana.isRomaji(input.target.value)) {
        this.familyAdd.firstName = input.target.value;
        this.familyEdit.firstName = input.target.value;
      } else {
        this.history.push(input.target.value);
        this.familyAdd.firstName = wanakana.toKatakana(
          historykana(this.history)
        );
        this.familyEdit.firstName = wanakana.toKatakana(
          historykana(this.history)
        );
      }
    },
    givenName_furigana(input) {
      if (wanakana.isRomaji(input.target.value)) {
        this.familyAdd.lastName = input.target.value;
        this.familyEdit.lastName = input.target.value;
      } else {
        this.history.push(input.target.value);
        this.familyAdd.lastName = wanakana.toKatakana(
          historykana(this.history)
        );
        this.familyEdit.lastName = wanakana.toKatakana(
          historykana(this.history)
        );
      }
    },
  },
  beforeMount() {
    this.gUserName = this.$keycloak.userName;
    this.getFamilies();
    console.log(this.gUserName);
  },
};
</script>
