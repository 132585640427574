<template>
  <div>
    <CRow>
      <CCol lg="12">
        <CCard>
          <CCardHeader>
            <h3 class="card-title">{{ $t("profilePage.userInformation") }}</h3>
          </CCardHeader>
          <CCardBody class="profile-home px-5">
            <div class="user-info-table mx-2">
              <dl class="row">
                <dt class="col-sm-12">
                  <h4 class="sub-title">
                    {{ $t("profilePage.registeredInformation") }}
                  </h4>
                </dt>
              </dl>
              <dl class="row r-content">

                <dd class="col-sm-8">
                  <dl class="row sub-r-content">
                    <dt class="col-sm-3">{{ $t("profilePage.userName") }}</dt>
                    <dd class="col-sm-9">{{ user.name }}</dd>
                    <dt class="col-sm-3">{{ $t("profilePage.cardID") }}</dt>
                    <dd class="col-sm-9">{{ user.cardId }}</dd>
                    <dt class="col-sm-3">{{ $t("profilePage.name") }}</dt>
                    <dd class="col-sm-9">{{ fullName }}</dd>
                    <dt class="col-sm-3">
                      {{ $t("profilePage.pronunciation") }}
                    </dt>
                    <dd class="col-sm-9">
                      {{ user.lastName }} {{ user.firstName }}
                    </dd>
                    <dt class="col-sm-3">{{ $t("profilePage.gender") }}</dt>
                    <dd class="col-sm-9">{{ user.gender | genderString }}</dd>
                    <dt class="col-sm-3">{{ $t("profilePage.birthDate") }}</dt>
                    <dd class="col-sm-9" v-if="user.birth">
                      {{ user.birth | formatDate }}
                    </dd>
                    <dd class="col-sm-9" v-if="!user.birth"></dd>
                    <!-- <dt class="col-sm-3">プロフィール画像</dt>
                    <dd class="col-sm-9">
                      <img class="avatar-img" :src="avatar.image" />
                    </dd> -->
                  </dl>
                </dd>
                
              </dl>
              <dl class="row">
                <dt class="col-sm-12">
                  <h4 class="sub-title">
                    {{ $t("profilePage.contactInformation") }}
                  </h4>
                </dt>
              </dl>
              <dl class="row r-content">
                <dd class="col-sm-8">
                  <dl class="row sub-r-content">
                    <dt class="col-sm-3">{{ $t("profilePage.email") }}</dt>
                    <dd class="col-sm-9">{{ user.email }}</dd>
                    <!-- <dt class="col-sm-3">Eメールアドレス承認状態</dt>
                    <dd class="col-sm-9">
                      {{ user.emailVerified | emailVerifiedString }}
                    </dd> -->
                    <dt class="col-sm-3">{{ $t("profilePage.telephone") }}</dt>
                    <dd class="col-sm-9">
                      {{ user.countryCode }} {{ user.mobile }}
                    </dd>
                  </dl>
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-12">
                  <h4 class="sub-title">{{ $t("profilePage.address") }}</h4>
                </dt>
              </dl>
              <dl class="row r-content">
                <dd class="col-sm-8">
                  <dl class="row sub-r-content">
                    <dt class="col-sm-3">{{ $t("profilePage.country") }}</dt>
                    <dd class="col-sm-9">{{ user.address.country.name }}</dd>
                    <dt class="col-sm-3">{{ $t("profilePage.zipCode") }}</dt>
                    <dd class="col-sm-9">{{ user.address.zipCode }}</dd>
                    <dt class="col-sm-3">{{ $t("profilePage.prefecture") }}</dt>
                    <dd class="col-sm-9">{{ user.address.state }}</dd>
                    <dt class="col-sm-3">
                      {{ $t("profilePage.municipality") }}
                    </dt>
                    <dd class="col-sm-9">{{ user.address.city }}</dd>
                    <dt class="col-sm-3">{{ $t("profilePage.lotNumber") }}</dt>
                    <dd class="col-sm-9">{{ user.address.address1 }}</dd>
                    <dt class="col-sm-3">{{ $t("profilePage.building") }}</dt>
                    <dd class="col-sm-9">{{ user.address.address2 }}</dd>
                  </dl>
                </dd>
              </dl>
            </div>
          </CCardBody>
          <CCardFooter class="px-5">
            <CButton class="info-button" @click="openEditDialog()"
              ><i class="icon cil-pencil">{{ $t("common.edit") }}</i></CButton
            >
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
    <CRow class="securityResetBox">
      <CCol lg="6">
        <CCard>
          <CCardHeader>
            <h3 class="card-title">
              {{ $t("profilePage.emailUpdatePageTitle") }}
            </h3>
          </CCardHeader>
          <CCardBody class="px-5">
            <CAlert show color="info">{{
              $t("profilePage.emailUpdateGuide")
            }}</CAlert>
            <CForm class="member-info modal-form">
              <CRow>
                <CCol lg="12">
                  <CInput
                    type="email"
                    :label="$t('profilePage.newEmail')"
                    horizontal
                    v-model="newEmail"
                  />

                  <CAlert
                    show
                    color="danger"
                    v-if="$v.newEmail.$dirty && !$v.newEmail.required"
                  >
                    {{ $t("profilePage.emailUpdateSolution") }}
                  </CAlert>
                  <CAlert
                    show
                    color="danger"
                    v-if="
                      $v.newEmail.$dirty && !$v.newEmail.alphaEmailValidator
                    "
                  >
                    {{ $t("profilePage.emailUpdateError") }}
                  </CAlert>
                </CCol>
              </CRow>
            </CForm>
          </CCardBody>
          <CCardFooter class="px-5">
            <CButton
              class="info-button border-white"
              v-on:click="
                $v.newEmail.$invalid ? requiredM() : (checkEmailModal = true)
              "
              v-bind:class="$v.newEmail.$invalid ? 'inactive' : 'active'"
            >
              <i class="icon cil-envelope-closed"></i>
              {{ $t("profilePage.emailUpdateButton") }}
            </CButton>
          </CCardFooter>
        </CCard>
      </CCol>

      <CCol lg="6">
        <CCard>
          <CCardHeader>
            <h3 class="card-title">
              {{ $t("profilePage.passwordUpdatePageTitle") }}
            </h3>
          </CCardHeader>
          <CCardBody class="px-5">
            <CAlert show color="info" style="white-space: pre-wrap">
              <!-- パスワード更新を行う場合は、下記のボタンを押してください。
              <br />本システムからパスワード更新について、ご案内メールが送信されます。
              <br />ご案内メールの記載内容に従って、パスワード更新を行ってください。 -->
              {{ $t("profilePage.passwordUpdateGuide") }}
            </CAlert>
          </CCardBody>
          <CCardFooter class="px-5">
            <CButton
              class="info-button border-white"
              v-on:click="submitUpdatePassword('パスワード更新')"
            >
              <i class="icon cil-lock-locked"></i
              >{{ $t("profilePage.passwordUpdateButton") }}
            </CButton>
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>

    <FamilyMember />

    <CModal
      :show.sync="editingModal"
      :closeOnBackdrop="false"
      :centered="true"
      size="xl"
      color="dark"
    >
      <CForm class="member-info modal-form">
        <CRow>
          <CCol>
            <h4 class="sub-title">
              {{ $t("profilePage.registeredInformation") }}
            </h4>
          </CCol>
        </CRow>

        <!-- TODO: previewAvatar が空の時、this.user.picture を表示する -->
        <!-- <CRow>
          <CCol lg="6">
            <div class="form-group form-row">
              <label class="col-form-label col-sm-3 upload-img"
                >プロフィール画像</label
              >

              <div class="col-sm-9">
                <img
                  class="avatar-img"
                  :src="avatar.previewImage"
                  v-if="avatar.previewImage"
                />
                <CInputFile
                  id="change_avatar"
                  accept="image/*"
                  @change="onAvatarChange($event)"
                />
              </div>
            </div>
          </CCol>
        </CRow> -->
        <CRow>
          <CCol lg="6">
            <CInput
              :label="$t('profilePage.userName')"
              horizontal
              plaintext
              required
              v-model="itemToEdit.name"
            />
          </CCol>

          <CCol lg="6">
            <CInput
              :label="$t('profilePage.cardID')"
              horizontal
              plaintext
              required
              v-model="itemToEdit.cardId"
            />
          </CCol>
        </CRow>

        <CRow>
          <CCol lg="6">
            <div
              class="text-danger input-info"
              v-if="!$v.itemToEdit.familyName.required"
            >
              <!-- 氏の入力は必須です -->
                    {{
                      $t("validations.required", {
                        vName: $t("profilePage.lastName"),
                      })
                    }}
            </div>
            <div
              class="text-danger input-info"
              v-if="!$v.itemToEdit.familyName.maxLength"
            >
              <!-- 氏は{{ limited.familyName.maxLength }}文字以下で入力してください -->
                    {{
                      $t("validations.maxLength", {
                        vName: $t("profilePage.lastName"),
                        vMax:
                          limited.familyName.maxLength - 1,
                      })
                    }}
            </div>
            <!-- <CInput
              label="氏"
              horizontal
              required
              v-model="itemToEdit.familyName"
            /> -->

            <div class="form-group form-row">
              <label class="col-form-label col-sm-3">{{
                $t("profilePage.lastName")
              }}</label>
              <div class="col-sm-9">
                <input
                  type="text"
                  class="form-control"
                  v-model="itemToEdit.familyName"
                  v-on:input="familyName_furigana"
                />
              </div>
            </div>
          </CCol>

          <CCol lg="6">
            <div
              class="text-danger input-info"
              v-if="!$v.itemToEdit.givenName.required"
            >
              <!-- 名の入力は必須です -->
                    {{
                      $t("validations.required", {
                        vName: $t("profilePage.firstName"),
                      })
                    }}
            </div>
            <div
              class="text-danger input-info"
              v-if="!$v.itemToEdit.givenName.maxLength"
            >
              <!-- 名は{{ limited.givenName.maxLength }}文字以下で入力してください -->
                    {{
                      $t("validations.maxLength", {
                        vName: $t("profilePage.firstName"),
                        vMax:
                          limited.givenName.maxLength - 1,
                      })
                    }}
            </div>
            <!-- <CInput
              label="名"
              horizontal
              required
              v-model="itemToEdit.givenName"
            /> -->
            <div class="form-group form-row">
              <label class="col-form-label col-sm-3">{{
                $t("profilePage.firstName")
              }}</label>
              <div class="col-sm-9">
                <input
                  type="text"
                  class="form-control"
                  v-model="itemToEdit.givenName"
                  v-on:input="givenName_furigana"
                />
              </div>
            </div>
          </CCol>
        </CRow>

        <CRow>
          <CCol lg="6">
            <div
              class="text-danger input-info"
              v-if="!$v.itemToEdit.firstName.required"
            >
              <!-- 氏（カナ）の入力は必須です -->
                    {{
                      $t("validations.required", {
                        vName: $t("profilePage.lastNamePronunciation"),
                      })
                    }}
            </div>
            <div
              class="text-danger input-info"
              v-if="!$v.itemToEdit.firstName.isKana"
            >
              <!-- 氏（カナ）はカタカナを入力してください -->
                    {{
                      $t("validations.kana", {
                        vName: $t("profilePage.lastNamePronunciation"),
                      })
                    }}
            </div>
            <div
              class="text-danger input-info"
              v-if="!$v.itemToEdit.firstName.maxLength"
            >
              <!-- 氏（カナ）は{{
                limited.firstName.maxLength
              }}文字以下で入力してください -->
                    {{
                      $t("validations.maxLength", {
                        vName: $t("profilePage.lastNamePronunciation"),
                        vMax:
                          limited.firstName.maxLength - 1,
                      })
                    }}
            </div>
            <CInput
              :label="$t('profilePage.lastNamePronunciation')"
              horizontal
              required
              v-model="itemToEdit.firstName"
            />
          </CCol>

          <CCol lg="6">
            <div
              class="text-danger input-info"
              v-if="!$v.itemToEdit.lastName.required"
            >
              <!-- 名（カナ）の入力は必須です -->
                    {{
                      $t("validations.required", {
                        vName: $t("profilePage.firstNamePronunciation"),
                      })
                    }}
            </div>
            <div
              class="text-danger input-info"
              v-if="!$v.itemToEdit.lastName.isKana"
            >
              <!-- 名（カナ）はカタカナを入力してください -->
                    {{
                      $t("validations.kana", {
                        vName: $t("profilePage.firstNamePronunciation"),
                      })
                    }}
            </div>
            <div
              class="text-danger input-info"
              v-if="!$v.itemToEdit.lastName.maxLength"
            >
              <!-- 名（カナ）は{{
                limited.lastName.maxLength
              }}文字以下で入力してください -->
                    {{
                      $t("validations.maxLength", {
                        vName: $t("profilePage.firstNamePronunciation"),
                        vMax:
                          limited.lastName.maxLength - 1,
                      })
                    }}
            </div>
            <CInput
              :label="$t('profilePage.firstNamePronunciation')"
              horizontal
              required
              v-model="itemToEdit.lastName"
            />
          </CCol>
        </CRow>

        <CRow>
          <CCol lg="6">
            <div
              class="text-danger input-info"
              v-if="!$v.itemToEdit.gender.required"
            >
              <!-- 性別の選択は必須です -->
                    {{
                      $t("validations.selectRequired", {
                        vName: $t("profilePage.gender"),
                      })
                    }}
            </div>
            <CSelect
              :label="$t('profilePage.gender')"
              horizontal
              :options="options.genderOptions"
              :value.sync="itemToEdit.gender"
            />
          </CCol>

          <CCol lg="6">
            <div
              class="text-danger input-info"
              v-if="!$v.itemToEdit.birth.required"
            >
              <!-- 生年月日の入力は必須です -->
                    {{
                      $t("validations.required", {
                        vName: $t("profilePage.birthDate"),
                      })
                    }}
            </div>
            <CInput
              :label="$t('profilePage.birthDate')"
              type="date"
              horizontal
              required
              v-model="itemToEdit.birth"
            />
          </CCol>
        </CRow>

        <CRow></CRow>

        <CRow>
          <CCol>
            <h4 class="sub-title">
              {{ $t("profilePage.contactInformation") }}
            </h4>
          </CCol>
        </CRow>

        <CRow>
          <CCol lg="6">
            <CInput
              :label="$t('profilePage.email')"
              horizontal
              plaintext
              required
              v-model="itemToEdit.email"
            />
          </CCol>
        </CRow>

        <!-- <CRow>
          <CCol lg="6">
            <div class="form-group form-row">
              <label class="col-form-label col-sm-3 upload-img"
                >Eメールアドレス承認状態</label
              >

              <div class="col-sm-9">
                {{ itemToEdit.emailVerified | emailVerifiedString }}
              </div>
            </div>
          </CCol>
        </CRow> -->
        <CRow>
          <CCol lg="6">
            <CSelect
              :label="$t('profilePage.countryCode')"
              horizontal
              :options="codeOptions"
              :value.sync="itemToEdit.countryCode"
            >
              {{ itemToEdit.countryCode }}
            </CSelect>
          </CCol>
        </CRow>
        <CRow>
          <CCol lg="6">
            <div
              class="text-danger input-info"
              v-if="!$v.itemToEdit.mobile.required"
            >
              <!-- 電話番号の入力は必須です -->
                    {{
                      $t("validations.required", {
                        vName: $t("profilePage.telephone"),
                      })
                    }}
            </div>
            <div
              class="text-danger input-info"
              v-if="!$v.itemToEdit.mobile.numeric"
            >
              <!-- 電話番号は数値を入力してください -->
                    {{
                      $t("validations.numeric", {
                        vName: $t("profilePage.telephone"),
                      })
                    }}
            </div>
            <div
              class="text-danger input-info"
              v-if="!$v.itemToEdit.mobile.maxLength"
            >
              <!-- 電話番号は{{ limited.mobile.maxLength }}文字以下で入力してください -->
                    {{
                      $t("validations.maxLength", {
                        vName: $t("profilePage.telephone"),
                        vMax:
                          limited.mobile.maxLength - 1,
                      })
                    }}
            </div>
            <CInput
              :label="$t('profilePage.telephone')"
              :description="$t('profilePage.telephoneGuide')"
              horizontal
              required
              v-model="itemToEdit.mobile"
            />
          </CCol>
        </CRow>

        <CRow></CRow>

        <CRow>
          <CCol>
            <h4 class="sub-title">{{ $t("profilePage.address") }}</h4>
          </CCol>
        </CRow>

        <CRow>
          <CCol lg="6">
            <div
              class="text-danger input-info"
              v-if="!$v.itemToEdit.address.country.required"
            >
              <!-- 国の選択は必須です -->
                    {{
                      $t("validations.selectRequired", {
                        vName: $t("profilePage.country"),
                      })
                    }}
            </div>
            <CSelect
              :label="$t('profilePage.country')"
              horizontal
              :options="countryOptions"
              :value.sync="itemToEdit.address.country.id"
            />
          </CCol>
        </CRow>

        <CRow>
          <CCol lg="6">
            <div
              class="text-danger input-info"
              v-if="!$v.itemToEdit.address.zipCode.required"
            >
              <!-- 郵便番号の入力は必須です -->
                    {{
                      $t("validations.required", {
                        vName: $t("profilePage.zipCode"),
                      })
                    }}
            </div>
            <div
              class="text-danger input-info"
              v-if="!$v.itemToEdit.address.zipCode.maxLength"
            >
              <!-- 郵便番号は{{
                limited.address.zipCode.maxLength
              }}文字以下で入力してください -->
                    {{
                      $t("validations.maxLength", {
                        vName: $t("profilePage.telephone"),
                        vMax:
                          limited.address.zipCode.maxLength - 1,
                      })
                    }}
            </div>
            <CInput
              :label="$t('profilePage.zipCode')"
              horizontal
              required
              v-model="itemToEdit.address.zipCode"
            />
          </CCol>
          <CCol lg="4">
            <CButton color="success" @click="getAddress()"
              ><i class="icon cil-location-pin"></i> {{$t("common.addressSearchButton")}}</CButton
            >
          </CCol>
        </CRow>
        <CRow>
          <CCol lg="6">
            <div
              class="text-danger input-info"
              v-if="!$v.itemToEdit.address.state.required"
            >
              <!-- 都道府県の入力は必須です -->
                    {{
                      $t("validations.required", {
                        vName: $t("profilePage.prefecture"),
                      })
                    }}
            </div>
            <div
              class="text-danger input-info"
              v-if="!$v.itemToEdit.address.state.maxLength"
            >
              <!-- 都道府県は{{
                limited.address.state.maxLength
              }}文字以下で入力してください -->
                    {{
                      $t("validations.maxLength", {
                        vName: $t("profilePage.prefecture"),
                        vMax:
                          limited.address.state.maxLength - 1,
                      })
                    }}
            </div>
            <CInput
              :label="$t('profilePage.prefecture')"
              horizontal
              required
              v-model="itemToEdit.address.state"
            />
          </CCol>
          <CCol lg="6">
            <div
              class="text-danger input-info"
              v-if="!$v.itemToEdit.address.city.required"
            >
              <!-- 市区町村の入力は必須です -->
                    {{
                      $t("validations.required", {
                        vName: $t("profilePage.municipality"),
                      })
                    }}
            </div>
            <div
              class="text-danger input-info"
              v-if="!$v.itemToEdit.address.city.maxLength"
            >
              <!-- 市区町村は{{
                limited.address.city.maxLength
              }}文字以下で入力してください -->
                    {{
                      $t("validations.maxLength", {
                        vName: $t("profilePage.municipality"),
                        vMax:
                          limited.address.city.maxLength - 1,
                      })
                    }}              
            </div>
            <CInput
              :label="$t('profilePage.municipality')"
              horizontal
              required
              v-model="itemToEdit.address.city"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol lg="6">
            <div
              class="text-danger input-info"
              v-if="!$v.itemToEdit.address.address1.required"
            >
              <!-- 地番番地の入力は必須です -->
                    {{
                      $t("validations.required", {
                        vName: $t("profilePage.lotNumber"),
                      })
                    }}
            </div>
            <div
              class="text-danger input-info"
              v-if="!$v.itemToEdit.address.address1.maxLength"
            >
              <!-- 地番番地は{{
                limited.address.address1.maxLength
              }}文字以下で入力してください -->
                    {{
                      $t("validations.maxLength", {
                        vName: $t("profilePage.lotNumber"),
                        vMax:
                          limited.address.address1.maxLength - 1,
                      })
                    }}    
            </div>
            <CInput
              :label="$t('profilePage.lotNumber')"
              horizontal
              required
              v-model="itemToEdit.address.address1"
            />
          </CCol>

          <CCol lg="6">
            <div
              class="text-danger input-info"
              v-if="!$v.itemToEdit.address.address2.maxLength"
            >
              <!-- 建物・その他は{{
                limited.address.address2.maxLength
              }}文字以下で入力してください -->
                    {{
                      $t("validations.maxLength", {
                        vName: $t("profilePage.building"),
                        vMax:
                          limited.address.address2.maxLength - 1,
                      })
                    }}   
            </div>
            <CInput
              :label="$t('profilePage.building')"
              horizontal
              required
              v-model="itemToEdit.address.address2"
            />
          </CCol>
        </CRow>
      </CForm>
      <template #header>
        <h6 class="modal-title">{{$t("profilePage.profileEditModalTitle")}}</h6>
      </template>
      <template #footer>
        <CButton @click="cancelEditProfile()" color="secondary"
          ><i class="icon cil-x"></i>{{ $t("common.cancel") }}</CButton
        >
        <CButton
          @click="saveProfile()"
          color="success"
          :disabled="$v.itemToEdit.$invalid"
          ><i class="icon cil-save"></i>{{ $t("common.save") }}</CButton
        >
      </template>
    </CModal>

    <CModal
      :show.sync="ConfirmModal"
      :no-close-on-backdrop="true"
      :centered="true"
      :title="ModalTitle"
      color="dark"
    >
      <!-- パスワード更新 -->
      <div class="modal-body px-2">
        <p>
          {{
            ModalTitle
          }}{{ $t("profilePage.emailGuide.message1") }}
          <br />{{ $t("profilePage.emailGuide") }}
        </p>
      </div>
      <template #header>
        <h6 class="modal-title">{{ ModalTitle }}</h6>
      </template>
      <template #footer>
        <CButton @click="ConfirmModal = false" color="danger">{{
          $t("common.close")
        }}</CButton>
      </template>
    </CModal>

    <CModal
      :show.sync="checkEmailModal"
      :closeOnBackdrop="false"
      :centered="true"
      color="dark"
    >
      <!-- メール更新確認 -->
      <div class="modal-body px-2">
        <!-- <p>
          メールアドレスは<b class="mx-1">{{ newEmail }}</b
          >に変更してよろしでしょうか

          <CAlert color="info"
            >変更してから新たなメールアドレスに認証メールを送信しますから、ご確認のほどお願い致します。</CAlert
          >
        </p> -->
                <p>
            <span>
                    {{
                      $t("profilePage.emailUpdateConfirm.message1", {
                        email: newEmail,
                      })
                    }}  
            </span>
          <CAlert color="info"
            >{{ $t("profilePage.emailUpdateConfirm.message2") }}</CAlert
          >
        </p>
        <!-- <b>agree: {{changeUserNameAgree}}</b>
        <b>email: {{isUserEmail}}</b> -->
        <!-- v-if="!isUserEmail" -->
        <label class="check-inline grey-bg">
          <input type="checkbox" v-model="changeUserNameAgree" />
          <!-- <span
            >ユーザー名も<b class="mx-1">{{ newEmail }}</b
            >に変更します -->
                      <!-- <span
            >{{ $t("profilePage.emailUpdateConfirm.message4") }}<b class="mx-1">{{ newEmail }}</b
            >{{ $t("profilePage.emailUpdateConfirm.message5") }} 
            </span>-->
            <span>
                    {{
                      $t("profilePage.emailUpdateConfirm.message3", {
                        email: newEmail,
                      })
                    }}  
            </span>
          
        </label>
        <!-- CAlert color="warning">ユーザー名を変更してから自動的にログアウトして、再びログインが必須です</!-->
      </div>
      <template #header>
        <!-- <h6 class="modal-title">Eメールアドレス更新確認</h6> -->
        <h6 class="modal-title">{{ $t("profilePage.emailConfirmModalTitle") }}</h6>
      </template>
      <template #footer>
        <CButton @click="checkEmailModal = false" color="danger">{{
          $t("common.cancel")
        }}</CButton>
        <CButton @click="submitUpdateEmail()" color="success">{{
          $t("common.change")
        }}</CButton>
      </template> </CModal
    ><CModal
      :show.sync="ConfirmEmailModal"
      :closeOnBackdrop="false"
      :centered="true"
      color="dark"
    >
      <!-- メールアドレス更新 -->
      <div class="modal-body px-2">

                <p>
          {{ $t("profilePage.emailUpdateConfirm.message4") }}
          <br />{{ $t("profilePage.emailUpdateConfirm.message5") }}
        </p>
        <!--CAlert color="warning" v-if="!changeUserNameAgree" >ユーザー名も変更されたのでログアウトします。再度ログインしてください。
          </!--CAlert-->
      </div>
      <template #header>
        <!-- <h6 class="modal-title">Eメールアドレス更新完了</h6> -->
        <h6 class="modal-title">{{ $t("profilePage.emailSuccessModalTitle") }}</h6>
      </template>
      <template #footer>
        <CButton @click="closeMailUpdateModal()" color="danger">{{
          $t("common.close")
        }}</CButton>
      </template>
    </CModal>

    <CModal
      :show.sync="ConfirmPassUpdateModal"
      :closeOnBackdrop="false"
      :centered="true"
      :title="ModalTitle"
      color="dark"
    >
      <!-- パスワード更新 -->
      <div class="modal-body px-2">
        <!-- <p>登録されたEメールアドレスにパスワード更新のリンクを送信しました。</p> -->
        <p>{{ $t("profilePage.paswordUpdateGuide") }}</p>
      </div>
      <template #header>
        <h6 class="modal-title">{{ ModalTitle }}</h6>
      </template>
      <template #footer>
        <CButton @click="ConfirmPassUpdateModal = false" color="danger">{{
          $t("common.close")
        }}</CButton>
      </template>
    </CModal>

    <CModal
      color="success"
      :closeOnBackdrop="false"
      :centered="true"
      :show.sync="successModal"
    >
      {{ $t("commonMessage.updateSuccessText") }}
      <template #header>
        {{ $t("commonMessage.updateSuccessHeader") }}
      </template>
      <template #footer>
        <CButton @click="successModal = false" color="secondary">
          <i class="icon cil-x"></i> {{ $t("common.close") }}
        </CButton>
      </template>
    </CModal>
    <CModal
      color="danger"
      closeOnBackdrop
      :centered="true"
      :show.sync="errorModal"
    >
      <p>{{ errorMessage }}</p>
      <template #header>
        <h4 class="modal-title">{{ $t("common.error") }}</h4>
      </template>
      <template #footer>
        <CButton @click="errorModal = false" color="secondary">
          <i class="icon cil-x"></i> {{ $t("common.close") }}
        </CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
// import Vue from 'vue'
import i18n from "@/i18n";
import axios from "axios";
import config from "@/config";
import {
  required,
  maxLength,
  numeric,
  helpers,
} from "vuelidate/lib/validators";
import FamilyMember from "./components/FamilyMember";
import Options from "./components/Options";

import historykana from "historykana";
import * as wanakana from "wanakana";

//会員システムのurl
const LIBERTY_MEMBER_SERVER_URL = config.LIBERTY_MEMBER_SERVER_URL;
// const LIBERTY_MEMBER_SERVER_URL = "https://points.liberty-resort.net/";

const alphaEmailValidator = helpers.regex(
  "alphaEmail",
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);
const avatar_url =
  "https://gaa9xd7j0g.execute-api.ap-northeast-1.amazonaws.com/develop";
const zipcode_url =
  "https://jxamy7mdqg.execute-api.ap-northeast-1.amazonaws.com/api/";

const limited = {
  familyName: { maxLength: 100 },
  givenName: { maxLength: 100 },
  firstName: { maxLength: 100 },
  lastName: { maxLength: 100 },
  mobile: { maxLength: 20 },
  address: {
    zipCode: { maxLength: 10 },
    state: { maxLength: 255 },
    city: { maxLength: 255 },
    address1: { maxLength: 255 },
    address2: { maxLength: 255 },
  },
};

export default {
  components: {
    FamilyMember,
  },
  name: "Profile",
  data() {
    return {
      furigana: "",
      newEmail: "",
      history: [],
      ModalTitle: "",
      errorMessage: "",
      editingModal: false,
      ConfirmModal: false,
      ConfirmEmailModal: false,
      checkEmailModal: false,
      ConfirmPassUpdateModal: false,
      successModal: false,
      errorModal: false,
      withdrawalModal: false,
      withdrawalAgree: false,
      changeUserNameAgree: false,
      withdrawalConfirmModal: false,
      withdrawalCompleteModal: false,
      engFirstName: true,
      engLastName: true,
      isUserEmail: false,
      codeOptions: [],
      countryOptions: [],
      options: Options.data(),
      user: {
        firstName: "",
        lastName: "",
        familyName: "",
        givenName: "",
        gender: "",
        birth: "",
        userName: "",
        cardId: "",
        email: "",
        emailVerified: true,
        countryCode: "",
        mobile: "",
        address: {
          state: "",
          city: "",
          address1: "",
          address2: "",
          zipCode: "",
          country: {
            id: "",
            name: "",
            fullName: "",
            alpha2: "",
            alpha3: "",
            dial: "",
            flagData: "",
          },
        },
        picture: "",
      },
      itemToEdit: {
        firstName: "",
        lastName: "",
        familyName: "",
        givenName: "",
        gender: "",
        birth: "",
        userName: "",
        countryCode: "",
        mobile: "",
        address: {
          state: "",
          city: "",
          address1: "",
          address2: "",
          zipCode: "",
          country: {
            id: "",
            name: "",
            fullName: "",
            alpha2: "",
            alpha3: "",
            dial: "",
            flagData: "",
          },
        },
        picture: "",
      },
      avatar: {
        image: "",
        previewImage: "",
        type: "",
      },
      limited: limited,
    };
  },
  validations() {
    const isKana = helpers.regex("isKana", /^([ァ-ヴ][ァ-ヴー・]*)$/);

    return {
      withdrawalModal: {
        required: { allowFalse: false },
      },
      itemToEdit: {
        familyName: {
          required,
          maxLength: maxLength(limited.familyName.maxLength),
        },
        givenName: {
          required,
          maxLength: maxLength(limited.givenName.maxLength),
        },
        firstName: {
          required,
          isKana: this.engFirstName ? false : isKana,
          maxLength: maxLength(limited.firstName.maxLength),
        },
        lastName: {
          required,
          isKana: this.engLastName ? false : isKana,
          maxLength: maxLength(limited.lastName.maxLength),
        },
        gender: { required },
        birth: { required },
        mobile: {
          required,
          numeric,
          maxLength: maxLength(limited.mobile.maxLength),
        },
        address: {
          country: { required },
          zipCode: {
            required,
            maxLength: maxLength(limited.address.zipCode.maxLength),
          }, // Note: US9桁
          state: {
            required,
            maxLength: maxLength(limited.address.state.maxLength),
          },
          city: {
            required,
            maxLength: maxLength(limited.address.city.maxLength),
          },
          address1: {
            required,
            maxLength: maxLength(limited.address.address1.maxLength),
          },
          address2: {
            maxLength: maxLength(limited.address.address2.maxLength),
          },
        },
      },
      newEmail: { required, alphaEmailValidator, maxLength: maxLength(100) },
    };
  },
  filters: {
    genderString: function (value) {
      if (!value) return i18n.t("genderType.unknown");
      var retString;
      switch (value) {
        case "UNKNOWN":
          retString = i18n.t("genderType.unknown");
          // retString = this.$t('genderType.unknown');
          break;
        case "MALE":
          retString = i18n.t("genderType.male");
          break;
        case "FEMALE":
          retString = i18n.t("genderType.female");
          break;
        default:
          retString = i18n.t("genderType.default");
          break;
      }
      return retString;
    },
    emailVerifiedString: function (value) {
      if (!value) return i18n.t("emailVerified.default");
      switch (value) {
        case true:
          return i18n.t("emailVerified.true");
        case false:
          return i18n.t("emailVerified.false");
        default:
          return i18n.t("emailVerified.default");
      }
    },
  },

  methods: {
    getUserInformation: function () {
      //Login as registered user
      var urlRequest = `${LIBERTY_MEMBER_SERVER_URL}/rest/currentuser`;
      axios
        .get(urlRequest, {
          headers: { Authorization: `Bearer ${this.$keycloak.token}` },
        })
        .then((resp) => {
          this.user = resp.data;
          console.log("User Data:" + JSON.stringify(this.user));
          FamilyMember.methods.setFullName(this.fullName);
          const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          this.isUserEmail = re.test(String(this.user.name).toLowerCase());
          this.isUserEmail
            ? (this.changeUserNameAgree = true)
            : (this.changeUserNameAgree = false);
          // this.downloadAvatar();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getCountries: function () {
      //Login as registered user
      // var urlRequest = "/rest/country";
      var urlRequest = `${LIBERTY_MEMBER_SERVER_URL}/rest/country`;
      axios
        .get(urlRequest, {
          headers: { Authorization: `Bearer ${this.$keycloak.token}` },
        })
        .then((resp) => {
          console.log("getCountries:" + JSON.stringify(resp.data));

          this.countryOptions = [];
          this.codeOptions = [];
          resp.data.forEach((element) => {
            this.countryOptions.push({
              value: element.id,
              label: element.name,
            });
            // 国際電話の国番号をセット
            this.codeOptions.push({
              value: element.dial,
              label: element.name + " (" + element.dial + ")",
            });
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    openEditDialog() {
      this.resetProfile();
      this.getCountries();
      this.editingModal = true;
    },
    saveProfile: async function () {
      // 更新があればプロフィール画像登録を行う
      if (this.avatar.type != "") await this.uploadAvatar();

      // const urlRequest = "/rest/users/" + this.$keycloak.userName;
      var urlRequest =
        `${LIBERTY_MEMBER_SERVER_URL}/rest/users/` + this.$keycloak.userName;

      console.log("Request Data:" + JSON.stringify(this.itemToEdit));
      axios
        .put(urlRequest, this.itemToEdit, {
          headers: { Authorization: `Bearer ${this.$keycloak.token}` },
        })
        .then((resp) => {
          console.log("User profile updated:" + resp.data);
          this.editingModal = false;
          this.getUserInformation();
          this.successModal = true;
        })
        .catch((err) => {
          console.log("Updating profile error: " + err);
          this.errors = err;
          this.errorModal = true;
        });
    },
    // get date information
    getDateString: function (dateItem) {
      var today;
      if (dateItem) {
        today = new Date(dateItem);
      } else {
        today = new Date();
      }

      var dateString =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      return dateString;
    },
    // update password post
    submitUpdatePassword: function (title) {
      this.ModalTitle = title;
      var userInfo = {
        username: this.$keycloak.userName,
        password: "",
      };
      // Confirm current password & update password.
      //var urlRequest = `${LIBERTY_MEMBER_SERVER_URL}/rest/users/` + this.$keycloak.userName + "/password";
      var urlRequest = `${LIBERTY_MEMBER_SERVER_URL}/rest/currentuser/password`;
      axios
        .post(urlRequest, userInfo, {
          headers: { Authorization: `Bearer ${this.$keycloak.token}` },
        })
        .then((resp) => {
          console.log(resp);
          this.ConfirmPassUpdateModal = true;
        })
        .catch((err) => {
          this.errorMessage = err;
          this.errorModal = true;
          // alert("パスワード更新要求はエラーが発生しました：" + err);
        });
    },
    // put email updated
    submitUpdateEmail: function () {
      var userInfoE = {
        username: this.$keycloak.userName,
        email: this.newEmail,
        changeUsername: this.changeUserNameAgree, // fix it for change username as email
      };
      // var urlRequestE = "/rest/users/" + this.$keycloak.userName + "/email";
      var urlRequestE = `${LIBERTY_MEMBER_SERVER_URL}/rest/currentuser/email`;

      axios
        .put(urlRequestE, userInfoE, {
          headers: { Authorization: `Bearer ${this.$keycloak.token}` },
        })
        .then((resp) => {
          console.log(resp);
          this.checkEmailModal = false;
          this.ConfirmEmailModal = true;
          // this.getUserInformation();
          this.newEmail = "";
        })
        .catch((err) => {
          this.errorMessage = err;
          this.errorModal = true;
          // alert("Error更新要求はエラーが発生しました：" + err);
          // location.reload();
        });
    },
    onAvatarChange: function (e) {
      // CInputFile の場合
      const files = e;

      this.avatar.type = files[0].type;
      console.log("this.avatar.type: " + this.avatar.type);

      this.getBase64(files[0])
        .then((avatar) => (this.avatar.previewImage = avatar))
        .catch((error) =>
          this.setError(error, this.$t("profilePage.imageUploadError"))
        );
    },
    getBase64: function (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    getAddress: function () {
      // Get address by zipcode
      const urlRequest =
        zipcode_url + "?postcode=" + this.itemToEdit.address.zipCode;

      axios
        .get(urlRequest)
        .then((resp) => {
          console.log("resp.data: ", resp.data);
          this.itemToEdit.address.state = resp.data.PrefecturesName;
          this.itemToEdit.address.city =
            resp.data.CityName + resp.data.TownName;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    cancelEditProfile: function () {
      // 会員情報編集画面を閉じる
      this.resetProfile();
      this.editingModal = false;
    },
    resetProfile: function () {
      // 入力内容をリセット
      // note: Object.assign() は itemToEdit が user の参照になってしまうため却下
      //   例) this.itemToEdit = Object.assign({}, this.user);
      // note: スプレッド構文は、ネストしたオブジェクトが参照になるため却下
      //   例）this.itemToEdit = { ...this.user };
      //
      this.itemToEdit = JSON.parse(JSON.stringify(this.user));

      this.avatar.previewImage = this.avatar.image;
      this.avatar.type = "";
    },
    uploadAvatar: async function () {
      const urlRequest = avatar_url;
      const header = { headers: { ContentType: this.avatar.type } };
      // todo: ここでリサイズ処理が必要（100x100など）
      // todo-memo: 2020/09/25時点で resize-base64(動かない), sharp(node v12は動かない?), canvas(よくわからない)
      const original = this.avatar.previewImage.replace(
        /^data:\w+\/\w+;base64,/,
        ""
      );

      await axios
        .put(urlRequest, original, header)
        .then((resp) => {
          console.log("uploadAvatar:");
          console.log(resp.data);
          this.itemToEdit.picture = resp.data.key;
        })
        .catch((err) => {
          console.log("Upload avatar error: " + err);
          this.errors = err;
        });
    },
    downloadAvatar: function () {
      const key = this.user.picture;
      const urlRequest = avatar_url + "?key=" + key;
      const type = String(key).slice(".")[1]; // 拡張子を取得

      axios
        .get(urlRequest)
        .then((resp) => {
          console.log(resp);
          this.avatar.image = "data:image/" + type + ";base64," + resp.data;
          this.avatar.previewImage = this.avatar.image;
        })
        .catch((err) => {
          console.log("Download avatar error: " + err);
          this.errors = err;
        });
    },

    // TODO: 仕様確認
    /**
     * カードID編集ボタンクリック
     */
    onCardIdEditClick() {
      this.editCardIdModal = true;
    },
    requiredM() {
      this.$v.newEmail.$touch();
    },
    familyName_furigana(input) {
      if (wanakana.isRomaji(input.target.value)) {
        this.itemToEdit.firstName = input.target.value;
        this.engFirstName = true;
      } else {
        this.engFirstName = false;
        this.history.push(input.target.value);
        this.itemToEdit.firstName = wanakana.toKatakana(
          historykana(this.history)
        );
      }
    },
    givenName_furigana(input) {
      if (wanakana.isRomaji(input.target.value)) {
        this.itemToEdit.lastName = input.target.value;
        this.engLastName = true;
      } else {
        this.engLastName = false;
        this.history.push(input.target.value);
        this.itemToEdit.lastName = wanakana.toKatakana(
          historykana(this.history)
        );
      }
    },
    closeMailUpdateModal() {
      this.changeUserNameAgree ? this.$router.go() : this.getUserInformation();
      this.ConfirmEmailModal = false;
    },
  },
  beforeMount() {
    this.getUserInformation();
  },
  computed: {
    fullName() {
      return this.user.familyName + " " + this.user.givenName;
    },
  },
};
</script>
