<script>
export default {
  name: "Options",
  data() {
    return {
      // NOTE: 国番号および国は、REST APIから取得
      // codeOptions: [],
      // countryOptions: [],
      genderOptions: [
        { value: "UNKNOWN", label: "公開しない" },
        { value: "MALE", label: "男性" },
        { value: "FEMALE", label: "女性" },
      ],
      relationOptions: [
        { value: "SPOUSE", label: "配偶者" },
        { value: "CHILD", label: "子" },
        { value: "PARENT", label: "親" },
        { value: "OTHER", label: "その他" },
      ],
      userRoleOptions: [
        { value: "general", label: "一般ユーザー" },
        { value: "operator", label: "オペレーター" },
        { value: "operatorAdmin", label: "オペレーター管理者" },
      ],
      userGroupsOptions: [
        { value: "", label: "" },
        { value: "", label: "" },
        { value: "", label: "" },
      ]
    };
  },
}
</script>